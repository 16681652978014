import React from 'react';

import styled from 'styled-components';
import Heading from '../../styled/Heading';
import Paragraph from '../../styled/Paragraph';
import ServiceCard from '../../styled/ServiceCard';

const Wrapper = styled.section`
  padding-top:5%;
  h2{
    text-align:center;
    margin-bottom:20px;
  }
  p{
    margin-bottom:30px;
    text-align:center;
    padding:0 5%;
  }
  .bottom-cards{
    display:-webkit-flex;
    padding:20px 0 20px 5%;
    overflow-x:scroll;
    @media(min-width:768px){
      overflow: unset;
      display: flex;
      flex-wrap:wrap;
      justify-content: center;
    }
    
    p{
      margin-bottom:0;
      text-align:left;
      padding-top:20px;
      @media (min-width: 1200px) {
          font-size:14px;
        }
    }
  }
  a{
    text-decoration:none;
    color:${props => props.theme.color.blue};
    font-size:18px;
    padding-bottom:5px;
    font-family:${props => props.theme.fam.regular};
    border-bottom:1px solid ${props => props.theme.color.blue};
    text-align:center;
    display: block;
    width:max-content;
    margin:0 auto;
    margin-top:30px;
  }
`

const data = [
  {
    image:"Payroll",
    text:"To deliver the most superior payroll service that is efficient, flexible and easy to use allowing you to focus on areas that add more value to your organizations.",
  },
  // {
  //   image:"Executive",
  //   text:"Executive Pay-view is an ideal payroll analysis tool for Executives used in decision–making, cost control, risk control and payroll fraud prevention, as well as in payroll auditing.",
  // },
  {
    image:"HR",
    text:"Automating Human Resources Through Cutting Edge Software",
  },
]

function Service() {
  return (
    <Wrapper>
      <Heading>Our Service Promise</Heading>
      <Paragraph>We pledge to provide you with an exceptional service experience based on our core service focus.</Paragraph>
      <div className="bottom-cards">
        {data.map((item, i)=>{
          return <ServiceCard heading={item.image} text={item.text} key={i} backGround={item.image} />
        })}
      </div>
     
    </Wrapper>
  )
}

export default Service