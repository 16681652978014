import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import About from '../components/Home/About'
import Banner from '../components/Home/Banner'
import Benefits from '../components/Home/Benefits'
import Mission from '../components/Home/Mission'
import Ready from '../components/Home/Ready'
import Service from '../components/Home/Service'
import Trusted from '../components/Home/Trusted'
import Why from '../components/Home/Why'

function Home() {
  return (
    <>
        <Header />
        <Banner />
        <Service />
        <About />
        <Mission />
        <Benefits />
        <Why />
        <Trusted />
        <Ready />
        
        <Footer />
    </>
  )
}

export default Home