import React from "react";
import { FaLinkedin } from "react-icons/fa";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #ffffff;
  margin:0 auto;
  display:block;
  box-shadow: 0px 28.83px 60px -15px rgba(92, 169, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  transition: all 0.7s ease;;
  max-width:295px;
  height:100px;
  @media(min-width:768px){
    &:hover{
      box-shadow: 0px 28.83px 60px -15px rgba(92, 169, 255, 0.5);
    }
  }
 
  .text {
    position: absolute;
    bottom: 0;
    left: 20px;
    padding-bottom: 17px;
    h5 {
      font-family: ${(props) => props.theme.fam.regular};
      color: ${(props) => props.theme.color.blue};
      font-size:16px;
    }
    h4 {
      margin-bottom: 18px;
      font-family: ${(props) => props.theme.fam.inter};
      color: ${(props) => props.theme.color.blue};
      padding-top: 5px;
      font-weight:900;
      font-size:16px;
    }
    p {
      font-family: ${(props) => props.theme.fam.regular};
      color: #858585;
      font-size:14px;
    }
  }
  .link {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 90px;
    background: ${(props) => props.theme.color.lightblue};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 0 0 0;
    z-index: 3;
    &:before {
      background: ${(props) => props.theme.color.blue};
      content: "";
      height: 17px;
      width: 80%;
      border-radius: 20px 0 0 0;
      position: absolute;
      z-index: 2;
      top: -16%;
      right: 0;
    }
    svg {
      height: 40px;
      width: 40px;
      color: #ffffff;
      padding: 20px;
    }
  }
`;

function TeamCard({ firstName, lastName, role, link, image }) {
  return (
    <a href={link}>
      <Wrapper>
       
        <div className="text">
          <h5 className="first-name">{firstName}</h5>
          <h4 className="last-name">{lastName}</h4>
          <p className="role">{role}</p>
        </div>
        <div className="link">
          <FaLinkedin />
        </div>
        <div className="bg"></div>
      </Wrapper>{" "}
    </a>
  );
}

export default TeamCard;
