import React from "react";
import styled from "styled-components";
import Paragraph from "./Paragraph";

const Wrapper = styled.div`
  width: 80%;
  max-width: 350px;
  margin-right: 25px;
  filter: drop-shadow(0px 4px 4px rgba(174, 171, 216, 0.5));
  min-width: 275px;
  box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding-bottom: 20px;
  transition:all 0.7s ease;
  @media(min-width:768px){
     margin-bottom:30px;
  }
  @media(min-width:992px){
    box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.05);
  &:hover{
    box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.3);
  }
  }
  .top-image {
    height: 133px;

    background: url("./images/${(props) => props.backGround}.png");
    background-size: cover;
  }
  p {
    padding: 20px;
  }
  h3{
    padding:20px;
    padding-bottom:0;
  }
`;

function ServiceCard({ text, backGround, heading }) {
  return (
    <Wrapper backGround={backGround}>
      <div className="top-image"></div>
      <h3>{heading}</h3>
      <Paragraph>{text}</Paragraph>
    </Wrapper>
  );
}

export default ServiceCard;
