import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";

const Wrapper = styled.section`
  padding: 5%;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  @media (min-width: 1200px) {
    padding: 5% 10%;
  }
  img {
    width: 100%;
  }
  h2 {
    position: relative;
    width: max-content;
    color: #000000;
    @media (min-width: 992px) {
      margin-bottom: 50px;
    }
    span {
      z-index: 2;
      position: relative;
    }
    img {
      position: absolute;
      right: 0;
      width: 60%;
      max-width: 150px;
      z-index: 1;
      bottom: -10px;
      @media (min-width: 992px) {
        max-width: 300px;
        bottom: -18px;
      }
    }
  }
  p {
    margin-bottom: 20px;
    color: #000000;
  }
  ul {
    list-style: unset;
    margin-left: 13px;
    li {
      font-family: ${(props) => props.theme.fam.regular};
      font-size: 14px;
      margin-bottom: 10px;
      color: #000000;
      @media (min-width: 1200px) {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
  h3 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 25px;
    color: #000000;
    text-transform: uppercase;
    br {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    @media (min-width: 768px) {
      font-size: 40px;
      width: 50%;
    }
    @media (min-width: 992px) {
      font-size: 55px;
    }
  }
  .bluecircle {
    display: none;
    @media (min-width: 768px) {
      position: absolute;
      bottom: -100px;
      left: 0;
      display: block;
      width: 100px;
    }
    @media (min-width: 1200px) {
      width: 200px;
    }
  }
  .container {
    @media (min-width: 768px) {
      display: flex;
    }
    @media (min-width: 1200px) {
      margin-top: 100px;
    }
  }
`;

function PayrollBureau() {
  return (
    <Wrapper>
      <img src="./images/bluecircle.png" className="bluecircle" alt="" />
      <Heading>
        <span>Payroll Bureau</span>
        <img src="./images/span.png" alt="" />
      </Heading>
      <Paragraph>
        A payroll bureau specialises in providing outsourced payroll services
        for other businesses. A payroll bureau will take care of specific parts
        of an organisation’s payroll processes. Our superior Payroll bureau
        services help organisations get rid of the hassle associated with
        payroll processing. From not keeping up to date with regulatory, not
        tracking overtime hours properly or miscalculating overtime wages, our
        payroll bureau services can give you peace of mind.
      </Paragraph>
      <div className="container">
        <h3>
          Why <br /> choose us?
        </h3>
        <div className="inner">
          <ul>
            <li>Payment Processing</li>
            <li>Pay slips</li>
            <li>Payroll Journals</li>
            <li>Payroll Returns</li>
            <li>24 hour support</li>
          </ul>
          <Paragraph>A world wide practice recognised for:</Paragraph>
          <ul>
            <li>Improving Efficiencies – gaining outside expertise</li>
            <li>Reducing costs</li>
            <li>
              Achieving legislative compliance on Payrolls – avoid penalties
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}

export default PayrollBureau;
