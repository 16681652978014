import React from 'react';
import Banner from '../components/About/Banner';
import Contact from '../components/Home/Contact';
import Footer from '../components/Footer';



function ContactPage() {
  return (
    <>
    <Banner pageName="Contact Us" />
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d450.94944429414556!2d31.03188344261146!3d-17.814632360641536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931af449b216023%3A0x9789a03d4d285916!2sConfidePay%20Payroll%20Sollutions!5e1!3m2!1sen!2szw!4v1681372838828!5m2!1sen!2szw" width="100%" height="450" allowfullscreen="" title="Confide Pay: Your Trusted Payment Solution for Secure Transactions" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    
    <Contact />
    <Footer />
    </>
  )
}

export default ContactPage