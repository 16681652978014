import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";
import { BsChevronRight } from "react-icons/bs";
import Fade from 'react-reveal'
import { Link } from "react-router-dom";

const ButtonWrapper = styled.button`
  background: ${(props) => props.theme.color.blue};
  height: 45px;
  width: 150px;
  border-radius: 10px;
  transform: translate(20px, 20px);
  border: none;

  transition: all 0.35s ease;
  color: #ffffff;
  position: relative;
  @media (min-width: 992px) {
    height: 60px;
    width: 219px;
  }
  &:hover {
    transform: translate(10px, 10px);
    @media (min-width: 1200px) {
      width: 200px;
    }
  }
  &:hover a {
    transform: translate(0px, 0px);
  }
  a {
    background: ${(props) => props.theme.color.lightblue};
    border-radius: 10px;
    transform: translate(-31px, -12px);
    height: 45px;
    display: block;
    width: 150px;
    transition: all 0.35s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.fam.regular};
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    @media (min-width: 992px) {
      height: 60px;
      width: 200px;
      font-size: 16px;
    }
  }
  svg {
    color: #ffffff;
    position: absolute;
    right: 0;
    top: 13px;
    height: 20px;
    right: 8px;
    @media (min-width: 992px) {
      top: 32%;
      right: 6%;
      width: 30px;
    }
  }
`;

const Wrapper = styled.section`
  padding: 5%;
  text-align: center;
  padding-bottom:15%;
  @media(min-width:768px){
    padding-botto:5%;
  }
  p {
    margin-top: 20px;
  }
  .grey-images {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media (min-width: 768px) {
      margin: 0 auto;
      max-width: 900px;
    }
    img {
      object-fit: contain;
      margin-bottom: 20px;

      &:first-child {
        width: 104px;
      }
      &:nth-child(2) {
        width: 126px;
      }
      &:nth-child(3) {
        width: 161px;
      }
      &:nth-child(4) {
        width: 142px;
      }
      &:nth-child(5) {
        width: 125px;
      }
      &:nth-child(6) {
        width: 142px;
      }
   
    }
  }
  .top-images, .bottom-images {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media (min-width: 768px) {
      margin: 0 auto;
      max-width: 900px;
    }
    img {
      object-fit: contain;
      margin-bottom:20px;

      &:first-child {
        width: 80px;
      }
      &:nth-child(2) {
        width: 156px;
      }
      &:nth-child(3) {
        width: 140px;
      }
      &:nth-child(4) {
        width: 170px;
      }
      &:nth-child(5) {
        width: 87px;
      }
      &:nth-child(6) {
        width: 130px;
      }
     
    }
  }
  .bottom-images{
   
    @media (min-width: 768px) {
      
      max-width: 1200px;
    }
    img{
      &:nth-child(1) {
        width: 165px;
      }
      &:nth-child(2) {
        width: 142px;
      }
      &:nth-child(3) {
        width: 142px;
      }
      &:nth-child(4) {
        width: 146px;
        @media(min-width:768px){
          width:90px;
        }
      }
      &:nth-child(5) {
        width: 146px;
        @media(min-width:768px){
          width:100px;
        }
      }
      &:nth-child(6) {
        width: 126px;
      }
      &:nth-child(7) {
        width: 120px;
      }
      &:nth-child(8) {
        width: 142px;
      }
    }
  }
  .bottom {
    margin-bottom:50px;
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }
    p {
      margin-bottom: 20px;
      @media (min-width: 768px) {
        margin-right: 30px;
      }
    }
  }
`;

function Trusted() {
  return (
    <Wrapper>
      <Heading>Trusted and Loved By</Heading>
     
      <Fade bottom>
      <div className="top-images">
        <img src="./images/employ.png" alt="" />
        <img src="./images/telco.png" alt="" />
        <img src="./images/logi.png" alt="" />
        <img src="./images/spk.png" alt="" />
        <img src="./images/telecel.png" alt="" />
        <img src="./images/fabs.png" alt="" />
        
      </div>
      </Fade>
    <div className="bottom-images">
    <Fade bottom>
    <img src="./images/wc.webp" alt="" />
        <img src="./images/nelspot.jpeg" alt="" />
        <img src="./images/africom.jpeg" alt="" />
        <img src="./images/cairns.png" alt="" />
        <img src="./images/astra.jpeg" alt="" />
        {/* <img src="./images/chai.png" alt="" />
        <img src="./images/cip.jpeg" alt="" /> */}
        <img src="./images/wcc.png" alt="" />
        </Fade>
    </div>
      
      <div className="bottom">
        <Paragraph>
          We work for a wide variety of clients in both the private and public
          sectors
        </Paragraph>
        <ButtonWrapper>
          <Link
            to="/contact"
            
          >
            Get in touch
          </Link>
          <BsChevronRight />
        </ButtonWrapper>
      </div>
      <Paragraph>
      Intelligent Solutions That Work Overtime For Yielding Results That Really Matter To Your Business.
</Paragraph><Paragraph>
2015 SMEA LOCAL LEGENDS employee wellness award winner.
      </Paragraph>
    </Wrapper>
  );
}

export default Trusted;
