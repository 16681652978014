import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const Wrapper = styled.h2`
    font-size:30px;
    font-family:${props => props.theme.fam.bold};
    position:relative;
    margin-bottom:30px;
    @media(min-width:768px){
      font-size:40px;
    }
    @media(min-width:1200px){
      font-size:45px;
    }
    img{
      position:absolute;
      z-index:-1;
    }
`

function Heading({children}) {
  return (
    <Fade bottom>
    <Wrapper>
      {children}
    </Wrapper>
    </Fade>
  )
}

export default Heading
