import React from "react";
import styled from "styled-components";
import BenefitCard from "../../styled/BenefitCard";
import Heading from "../../styled/Heading";
import SubHeading from "../../styled/SubHeading";


const Wrapper = styled.section`
  h4{
    text-align:center;
    padding:5%;
    @media(min-width:768px){
      padding:0;
      padding-top:5%;
    }
  }
  h2{
    text-align:center;
    padding:5%;
    line-height:40px;
    @media(min-width:768px){
      
    }
    img{
      position:absolute;
      right:50%;
      transform:translateX(50%);
      top:25px;
      width:150px;
      @media(min-width:768px){
        top:37%;
      }
    }
  }
  .cards-benefit{
    display:-webkit-flex;
    padding:20px 0 20px 5%;
    overflow-x:scroll;
    margin-bottom:10px;
    &::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
&::-webkit-scrollbar-thumb {
  background: ${props => props.theme.color.blue};
}
    @media(min-width:768px){
      overflow: unset;
      display: flex;
      flex-wrap:wrap;
      justify-content: center;
    }
    .benefit-card:hover  .img-top{
      max-height:150px;
}
.benefit-card:hover  h3{
  color:${props => props.theme.color.blue};
}
  }
  
`;

const data = [
  {
    bgImage:'benefitbg',
    heading:'Individual Attention', 
    text:'We will treat each client with the utmost care; we will develop and maintain a strong personal relationship; we will tailor make our products to suit every client individually; we will provide service with a commitment to professionalism, trust and the highest level of personal and professional integrity.'
  },
  {
    bgImage:'benefitbg',
    heading:'Quick Response', 
    text:'We will respond to a client’s urgent need immediately; we will return all messages and e-mail communications within 24 hours; we will deliver and review all work products on a timely and as agreed basis.'
  },
  {
    bgImage:'benefitbg',
    heading:'Proactivity', 
    text:'We are committed to understanding the goals and needs of our clients, responding to such needs with our best service, advice and products. We will strive to provide our clients with innovative solutions and opportunities to improve and grow their business'
  },
]

function Benefits() {
  return (
    <Wrapper>
      <SubHeading>Benefits of working with us</SubHeading>
      <Heading>Getting started is <br /> as easy as 1,2,3... <img src="./images/span.png" alt="" /></Heading>
      <div className="cards-benefit">
        {data.map((item, i)=>{
          return <BenefitCard key={i} bgImage={item.bgImage} heading={item.heading} text={item.text} />
        })}
      </div>
    </Wrapper>
  );
}

export default Benefits;
