import React from "react";

import styled from "styled-components";

import { Link } from "react-router-dom";


const Wrapper = styled.section`
  padding: 5%;
  .spinner {
           width: 56px;
           height: 56px;
           border-radius: 50%;
           background: radial-gradient(farthest-side,#474bff 94%,#0000) top/9px 9px no-repeat,
                  conic-gradient(#0000 30%,#474bff);
           -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
           animation: spinner-c7wet2 1s infinite linear;
           display:block;
           margin:0 auto;
        }
        
        @keyframes spinner-c7wet2 {
           100% {
              transform: rotate(1turn);
           }
        }
  .card {
    width: 100%;
    margin-bottom:30px;
    max-width:400px;
    @media(min-width:768px){
        width:45%;
    }
    .top-image {
      height: 200px;
      width: 100%;
      overflow:hidden;
      margin-bottom:15px;
    
    }
  }
  .container{
    display:flex;
    flex-wrap:wrap;
    gap:30px;
    justify-content: center;
  }
`;

const Card = styled.div`
  margin-bottom: 30px;
  .top-image {
    position: relative;
    display:flex;
    align-items:center;
    background:linear-gradient(to bottom, #00000011,#00000011), url('${props => props.bgImage}');
    background-size: cover;
    background-position: center center;
    justify-content:center;
    p {
      position: absolute;
      color:#ffffff;
      
      font-family:${props => props.theme.fam.regular};
      top:20px;
      left:10px;
     font-size:12px;
display: flex;
gap:5px;
span{
    padding:8px 10px;
     background: rgba(142, 142, 142, 0.2);
backdrop-filter: blur(6px);
border-radius: 4px;
}
/* Note: backdrop-filter has minimal browser support */


    }
    img {
      width: 100%;
      object-fit: contain;

    }
  }
  .text{
    h2{
      font-family:${props => props.theme.fam.inter};
      font-size:25px;
      margin-bottom:20px;
    }
    .details{
      display:flex;
      font-family:${props => props.theme.fam.regular};
      font-weight:100;
      margin-bottom:20px;
      align-items:center;
      gap:10px;

      .author{font-size:16px;font-weight:700;max-width:70%;}
      .date{
        font-size:15px;
      }
     img{
         object-fit:contain;
         width:30px;
     }
    }
.desc{
  font-family:${props => props.theme.fam.regular};
  font-size:15px;
  color:#6C757D;
  line-height:23px;
  margin-bottom:20px;
  img{
    width:100%;
    display:none;
  }
}
    
  }
  a{
    font-size:20px;
    font-family:${props => props.theme.fam.bold};
    color:#000000;
    position:relative;
    &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            transition: all 0.7s ease;
            bottom: -5%;
            left: 0;
            background: #000000;
          }
  }
`;




 


 
const BlogSection = ({articles}) => {
    // Split the content into words

    const shorten = (description) =>{
        let words = description.split(' ').slice(0, 23).join(' ');
        let shortDescription = description.length > words.length ? words + '...' : words;
  
        return shortDescription
      }

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: "long", day: "numeric", year: "numeric" };
        return date.toLocaleDateString("en-US", options);
      };
    
  return (
    <Wrapper>
     <div className="container">
          {articles && articles.map((article)=>{
            
            return  <Card
          bgImage={article.featured_image}
          className="card"
          key={article.ID}
        >
           <div className="top-image">
          {/* <p>{article.tags.forEach((item)=>{
            return  <span key={item.ID}>{item.name}</span>
          })}</p> */}
          
          
          </div>
        <div className="text">
            <h2>{article.title}</h2>
             <div className="details">
              
              <p className="author">{article.author.name}</p>
              <img src="./images/mydot.png" alt="" />
              <p className="date">{formatDate(article.date)}</p>
            </div>
            <p className="desc" dangerouslySetInnerHTML={{ __html: shorten(article.excerpt) }} />
          </div> 
          {/* Render article details */}

          {/* Render article image, if available, otherwise render default image */}
        
            <Link  to={`/blogs/${article.slug}`}>Read More</Link>
           
        </Card>
        })}
     </div>
      


        
       
    </Wrapper>
  );
};

export default BlogSection;
