import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const Wrapper = styled.p`
    font-family:${props => props.theme.fam.regular};
    font-size:14px;
    color:#000000;
    line-height:25px;
    @media (min-width:1200px) {
      font-size:16px;
    }
`

function Paragraph({children}) {
  return (
    <Fade bottom>
    <Wrapper>{children}</Wrapper>
    </Fade>
  )
}

export default Paragraph