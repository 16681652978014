/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import {useEffect, useState} from 'react';
import About from "./pages/About";
import Home from "./pages/Home";
import ResourcesPage from "./pages/ResourcesPage";
// import logo from './logo.png';
import ScrollToTop from "./ScrollTop";
import ReactWhatsappButton from "react-whatsapp-button";
import ThankYou from "./components/ThankYou";
import FaqPage from "./pages/FaqPage";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import PayrollPage from "./pages/PayrollPage";
import HRPage from "./pages/HRPage";
import PBPage from "./pages/PBPage";
import TrainingPage from "./pages/TrainingPage";
import OutsourcedPage from "./pages/OutsourcedPage";
import BlogPostDetails from "./components/Blog/BlogPostDetails";

import axios from "axios";


function App() {
  const body = document.body;
  let lastScroll = 0;
  
  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove("scroll-up");
      return;
    }
  
    if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
      body.classList.remove("scroll-up");
      body.classList.add("scroll-down");
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains("scroll-down")
    ) {
      body.classList.remove("scroll-down");
      body.classList.add("scroll-up");
    }
    lastScroll = currentScroll;
  });
  const dataLayer = window.dataLayer || [];

  useEffect(() => {
    // Add Google Analytics tracking code
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-VKW8B4BG9M";
    document.head.appendChild(script);
  
    function gtag() {
      dataLayer.push(arguments);
    }
  
    gtag('js', new Date());
    gtag('config', 'G-VKW8B4BG9M');
  }, [dataLayer]);


  
  const [articles, setArticles] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        "https://public-api.wordpress.com/rest/v1.1/sites/confidepay.wordpress.com/posts"
      );
      setArticles(result.data.posts);
    };
    fetchData();
  }, []);

  // let post;

  // if (item) {
  //   post = item.map((post, index) => (
  //     <ShowBlog key={index} {...post} {...profile} {...index} />
  //   ));
  // }
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route element={<Home />} exact="true" path="/" />
          <Route element={<About />} path="/about" />
          {/* <Route element={<ProductsPage />} path="/products-and-services" /> */}
          <Route element={<ResourcesPage />} path="/resources" />
          <Route element={<FaqPage />} path="/faqs" />
          <Route element={<BlogPage blogData={articles} />} path="/blogs" />
          <Route element={<BlogPostDetails blogData={articles} />} path="/blogs/:slug" />
          <Route element={<ContactPage />} path="/contact" />
          <Route element={<ThankYou />} path="/submitted"/>
          <Route element={<PayrollPage />} path="/payroll"/>
          <Route element={<HRPage />} path="/human-resources"/>
          <Route element={<PBPage />} path="/payroll-bureau"/>
          <Route element={<TrainingPage />} path="/training"/>
          <Route element={<OutsourcedPage />} path="/outsourced-salary-service"/>
        </Routes>
      </ScrollToTop>
      <ReactWhatsappButton  countryCode='263' phoneNumber='781192066' />
      {/* <FloatingWhatsApp phoneNumber="+263783997491" accountName="Confide Pay" avatar={logo} /> */}
    </Router>
  );
}

export default App;

// 2d913567f15cf93f9d2e8e6a262562415681becfe8737cc6c9e87424fd3eb8741