import React from "react";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";
import styled from "styled-components";
import Accordion from "../../styled/Accordion";

const Wrapper = styled.section`
  padding:5%;
  h2{
    text-align:center;
  }
  .inner{
    @media(min-width:992px){
        display:flex;
    }
    @media(min-width:1200px){
        padding:0 5%;
    }
  }
`;

function Faqs() {
  return (
    <Wrapper>
      <Heading>Frequently Asked Questions</Heading>
      <div className="inner">
        <Accordion>
          <div label="How do i handle tax calculations?">
            <Paragraph>
            Confidepay complies with ZIMRA and FDS. Tax tables are hard coded in the system and can only be updated if there is a statutory change.
            </Paragraph>
          </div>
          <div label="What should a payroll policy include?">
            <Paragraph>
            Confidepay makes clear how your business is complying with regulations. It should cover  the following : Commissions, PAYE, Funeral Policies, Medical Aid, NSSA, NEC, Loans
            </Paragraph>
          </div>
          <div label="How does Confidepay handle commissions in its payroll policy?">
            <Paragraph>
            Confidepay's payroll policy includes provisions for handling commissions as part of the payroll calculations and ensures compliance with relevant regulations and tax requirements.
            </Paragraph>
          </div>
          <div label="What is PAYE and how is it handled by Confidepay?">
            <Paragraph>
            PAYE (Pay As You Earn) is a tax deducted from employees' salaries at the source. Confidepay's payroll policy includes accurate calculation and deduction of PAYE based on applicable tax rates and regulations.
            </Paragraph>
          </div>
        </Accordion>
        <Accordion>
          <div label="How does Confidepay handle funeral policies in its payroll policy?">
            <Paragraph>
            Confidepay's payroll policy may include provisions for handling funeral policies, such as facilitating deductions or contributions towards funeral policies as per the company's policy and relevant regulations.
            </Paragraph>
          </div>
          <div label="Does Confidepay handle medical aid contributions in its payroll policy?">
            <Paragraph>
            Yes, Confidepay's payroll policy may include provisions for handling medical aid contributions, including deductions or contributions towards employees' medical aid schemes as per the company's policy and relevant regulations.
            </Paragraph>
          </div>
          <div label="How does Confidepay handle NSSA contributions in its payroll policy?">
            <Paragraph>
            Confidepay's payroll policy includes accurate calculation and deduction of NSSA (National Social Security Authority) contributions from employees' salaries as per the applicable rates and regulations set by NSSA.
            </Paragraph>
          </div>
          <div label="How are loans handled in Confidepay's payroll policy?">
            <Paragraph>
            Confidepay's payroll policy may include provisions for handling employee loans, such as facilitating loan deductions or repayments as per the company's loan policy and relevant regulations, while ensuring compliance with legal requirements and maintaining accurate payroll calculations.
            </Paragraph>
          </div>
        </Accordion>
      </div>
    </Wrapper>
  );
}

export default Faqs;