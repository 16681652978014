/* eslint-disable import/no-anonymous-default-export */
export default {
    fam:{
        regular:'AvenirNext-Regular',
        bold:'Urbanist-Bold',
        inter:'Inter-Bold'
    },
    color:{
        skyblue:" rgba(0, 122, 255, 1)",
        lightblue:"#5CA9FF",
        grey:"rgba(82, 78, 78, 1)",
        blue:"#1a156d",
        
    },
}