import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {BsChevronRight} from 'react-icons/bs';

const Wrapper = styled.button`
  background:${props => props.theme.color.blue};
  height:45px;
  width:150px;
  border-radius:10px;
  transform:translate(20px, 20px);
  border:none;

  transition:all 0.35s ease;
  color:#ffffff;
  position:relative;
  @media (min-width: 992px) {
          height:60px;
          width:219px;
        }
        &:hover{
          transform:translate(10px, 10px);
          @media (min-width:1200px) {
            width:200px;
          }
        }
        &:hover a{
          transform:translate(0px, 0px);
        }
  a{
    background:${props => props.theme.color.lightblue};
    border-radius:10px;
    transform:translate(-31px,-12px);
    height:45px;
    display: block;
    width:150px;
    transition:all 0.35s ease;
    display: flex;
    align-items:center;
    justify-content:center;
    font-family:${props => props.theme.fam.regular};
    color:#ffffff;
    text-decoration:none;
    font-size:14px;
    @media (min-width: 992px) {
          height:60px;
          width:200px;
          font-size:16px;
        }
  }
  svg{
    color:#ffffff;
    position:absolute;
    right:0;
    top:13px;
    height:20px;
    right:8px;
    @media (min-width: 992px) {
      top: 32%;
    right: 6%;
    width: 30px;
        }
  }
`;

function Button({ link, text }) {
  return (
    <Wrapper>
      <Link to={link}>{text}</Link>
      <BsChevronRight />
    </Wrapper>
  );
}

export default Button;
