import React from 'react'
import Banner from '../components/About/Banner'
import Footer from '../components/Footer'
import HumanResources from '../components/Products/HumanResources'
import Picture from '../styled/Picture'

function HRPage() {
  return (
    <>
        <Banner pageName="Human Resources" />
        <HumanResources />
        <Picture bgImage="pic2.webp" />
        <Footer />
    </>
  )
}

export default HRPage