import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/logo.png"

const Wrapper = styled.header`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  z-index: 10;
  justify-content: space-between;
  @media (min-width: 768px) {
    transform: translate(-50%, 0%);
    left: 50%;
    top: 0;
    width: 88%;
  }
  @media (min-width: 992px) {
    top: 20px;
  }

  img {
    width: 70px;
    z-index: 5;
    position: relative;
    margin-top: 11px;
    @media (min-width: 768px) {
      transform: translateX(-20%);
    }
  }
  .main{
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 4;
    background: ${props => props.theme.color.blue};
    top: 0;
    transition: all 0.3s ease;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 0;
    overflow: hidden;
    @media (min-width: 768px) {
      max-width: unset;
      flex-direction: row;
      background: transparent;
      height: max-content;
      align-items: center;
      width: max-content;
      justify-content: center;
      position: relative;
      margin-left: auto;
      overflow:unset;
      margin-right: 0;
    }
    @media (min-width: 992px) {
      /* transform: unset; */
    }
    li{
      position:relative;
      a, span {
        padding: 15px 20px;
        width: max-content;
        display: block;
        font-size: 16px;
        text-align:center;
        
        transition: all 0.7s ease;
        text-transform: uppercase;
        color: #ffffff;
        font-family: ${(props) => props.theme.fam.regular};
        font-weight: 600;
        letter-spacing: 0.02em;
        @media (min-width: 768px) {
          font-size: 11px;
        }
        @media (min-width: 992px) {
          font-size: 16px;
        }
        &:hover {
          color: ${(props) => props.theme.color.lightblue};
        }
      }
      span{
        margin:0 auto;
        display:block;
        position:relative;
      }
      .active {
        color: #ffffff;
        position: relative;
        font-weight:900;
        @media (min-width: 768px) {
          font-size: 12px;
          padding: 20px 0;
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            transition: all 0.3s ease;
            bottom: 30%;
            left: 0;
            background: #ffffff;
          }
        }
        @media (min-width: 992px) {
          font-size: 16px;
          padding: 20px;
          &:before {
            width: calc(100% - 40px);
            left: 50%;
            transform: translateX(-50%);
            bottom: 23%;
          }
        }
      }
    }
    .dropdown{
    transition: all 0.3s ease;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    overflow: hidden;
    max-height:0;
    @media(min-width:768px){
      position:absolute;
      top:100%;
      left:0;
      height:200px;
      display:flex;
      flex-direction:column;
      background:${props => props.theme.color.blue};
      border-radius:5px;
      
      li{
        a{
          font-size:14px;
          padding:10px 5px;
        }
      }
    }
    .active{
      @media(min-width:992px){
        &:before {
            width: calc(100% - 10px);
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
          }
      }
    }
    }
    .opendropdown{
      max-height:300px;
    }
  }
  .openedMenuSmall {
    max-width: 100%;
  }
  .menu {
    display: none;
  }
  .hamburger.small {
    padding: 25px 42px 25px 8px;
    outline: 1px solid #ffffff;
    margin-right: 5%;
    z-index: 10;
    span {
      width: 35px;
      height: 1px;
      border-radius: 10px;
      transition: all 0.3s ease;
      background: #ffffff;
      position: absolute;
      &:before {
        width: 100%;
        height: 100%;
        background: #ffffff;
        position: absolute;
        transition: all 0.3s ease;
        content: "";
        top: -6px;
      }
      &:after {
        width: 100%;
        height: 100%;
        background: #ffffff;
        position: absolute;
        transition: all 0.3s ease;
        content: "";
        top: 6px;
      }
    }
  }
  .open.small {
    span {
      background: transparent;
      &:before {
        top: 0;
        transform: rotate(495deg);
      }
      &:after {
        top: 0;
        transform: rotate(-495deg);
      }
    }
  }
  .small {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .menu {
    display: none;
    @media (min-width: 768px) {
      display: block;
      .hamburger.small {
        display: block;
        margin-right: 5%;
      }
    }
    
    .menu-inner {
      display: none;
    }
    @media (min-width: 768px) {
      .menu-inner {
        display: block;
        height: 100vh;
        width: max-content;
        position: absolute;
        background: #ffffff;
        right: -6.5%;
        top: -17%;
        transition: all 0.3s ease;
        overflow: hidden;

        z-index: 6;

        padding-bottom: 5%;
        max-width: 0;
        h5 {
          padding-top: 200px;
        }
        h5,
        p {
          width: max-content;
        }
      }
      .menu-open {
        max-width: 1000px;
        padding: 5%;
      }
    }
  }
  .contact-link {
    a {
      cursor: pointer;
    }
  }
  h5 {
    font-family: ${(props) => props.theme.fam.regular};
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    @media (min-width: 992px) {
      font-size: 25px;
    }
  }
`;

function Header() {
  const [smallClick, setSmallClick] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  return (
    <Wrapper className="header">
      <Link exact="true" to="/">
        <img src={logo} alt="Confide Pay Logo" />
      </Link>
      <div
        className={smallClick ? "hamburger small open" : "hamburger small"}
        onClick={() => setSmallClick(!smallClick)}
      >
        <span></span>
      </div>
      <ul className={smallClick ? "openedMenuSmall main" : "main"}>
        <li onClick={() => setSmallClick(false)}>
          <NavLink to="/" exact="true" activeclassname="active">
            Home
          </NavLink>
        </li>
        <li onMouseOver={() => setDropDown(true)} onMouseOut={() => setDropDown(false)}>
          <span  >
            Products & Services
          </span>
          <ul className={dropDown ? "opendropdown dropdown" : 'dropdown'}>
            <li onClick={() => setSmallClick(false)}>
              <NavLink to="/training" activeclassname="active">
              Training
              </NavLink>
            </li>
            <li onClick={() => setSmallClick(false)}>
              <NavLink to="/payroll-bureau" activeclassname="active">
              Payroll Bureau
              </NavLink>
            </li>
            <li onClick={() => setSmallClick(false)}>
              <NavLink to="/human-resources" activeclassname="active">
              Human Resources
              </NavLink>
            </li>
            <li onClick={() => setSmallClick(false)}>
              <NavLink to="/payroll" activeclassname="active">
                Payroll
              </NavLink>
            </li>
            <li onClick={() => setSmallClick(false)}>
              <NavLink to="/outsourced-salary-service" activeclassname="active">
                Outsourced Salary Service
              </NavLink>
            </li>
          </ul>
        </li>
        <li onClick={() => setSmallClick(false)}>
          <NavLink to="/about" activeclassname="active">
            About Us
          </NavLink>
        </li>
        <li onClick={() => setSmallClick(false)}>
          <NavLink to="/faqs" activeclassname="active">
            FAQs
          </NavLink>
        </li>
        <li onClick={() => setSmallClick(false)}>
          <NavLink to="/blogs" activeclassname="active">
            Blogs
          </NavLink>
        </li>
        <li onClick={() => setSmallClick(false)}>
          <NavLink to="/contact" activeclassname="active">
            Contact
          </NavLink>
        </li>
      </ul>
     
    </Wrapper>
  );
}

export default Header;
