/* eslint-disable react/jsx-no-target-blank */
import { TextField } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";
import SubHeading from "../../styled/SubHeading";
import { BsChevronRight } from "react-icons/bs";
import Fade from "react-reveal";
import axios from "axios";

const Wrapper = styled.section`
  padding: 10% 5%;
  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: 1200px) {
    padding: 10%;
  }
  h4 {
    margin-bottom: 20px;
  }
  h2 {
    img {
      position: absolute;
      left: 20%;
      bottom: 20px;
      width: 200px;
      @media (min-width: 992px) {
        bottom: -30px;
        width: 320px;
      }
    }
  }
  form {
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 20px;
      input,
      label {
        color: #111617 !important;
        font-family: ${(props) => props.theme.fam.regular} !important;
      }
      .MuiInputBase-root {
        &:before {
          border-bottom: 1px solid #c2c2c2 !important;
        }
      }
      [data-shrink="true"] {
        color: ${(props) => props.theme.color.blue} !important;
        &:after {
          border: ${(props) => props.theme.color.blue};
        }
      }
    }
  }
  .contact-inner {
    /* @media (min-width:992px) {
        width:55%;
} */
    &:first-child {
      @media (min-width: 992px) {
        width: 55%;
        margin-right: 50px;
      }
    }
    h5 {
      font-family: ${(props) => props.theme.fam.regular};
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 20px;
      @media (min-width: 992px) {
        font-size: 25px;
      }
    }
    p {
      @media (min-width: 992px) {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
    button {
      width: 180px;
      margin-top: 20px;
      @media (min-width: 992px) {
        width: 300px;
        &:hover {
          width: 280px;
        }
        &:hover a {
          width: 280px;
        }
        a {
          width: 280px;
        }
      }
    }
    form {
      [type="submit"] {
        background: ${(props) => props.theme.color.blue};
        height: 45px;
        font-family: ${(props) => props.theme.fam.regular};
        color: #ffffff;
        font-size: 16px;
        margin-top: 0;
        width: 100%;
        border: none;
        @media (min-width: 992px) {
          height: 60px;
          font-weight: 700;
        }
      }
    }
  }
`;

const ButtonWrapper = styled.button`
  background: ${(props) => props.theme.color.blue};
  height: 45px;
  width: 180px;
  border-radius: 10px;
  transform: translate(20px, 20px);
  border: none;

  transition: all 0.35s ease;
  color: #ffffff;
  position: relative;
  @media (min-width: 992px) {
    height: 60px;
    width: 219px;
  }
  &:hover {
    transform: translate(10px, 10px);
    @media (min-width: 1200px) {
      width: 200px;
    }
  }
  &:hover a {
    transform: translate(0px, 0px);
  }
  a {
    background: ${(props) => props.theme.color.lightblue};
    border-radius: 10px;
    transform: translate(-31px, -12px);
    height: 45px;
    display: block;
    width: 180px;
    transition: all 0.35s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.fam.regular};
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    @media (min-width: 992px) {
      height: 60px;
      width: 200px;
      font-size: 16px;
    }
  }
  svg {
    color: #ffffff;
    position: absolute;
    right: 0;
    top: 13px;
    height: 20px;
    right: 8px;
    @media (min-width: 992px) {
      top: 32%;
      right: 6%;
      width: 30px;
    }
  }
`;

function Contact() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFailed, setIsFailed] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Send form data to server or API endpoint
      const response = await axios.post(
        "https://formsubmit.co/f1136dc7a388beec8a9b5888f786b4cb",
        formData
      );
      console.log("Form submission successful:", response.data);
      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
        setFormData({
          fullName: "",
          email: "",
          message: "",
        });
      }, 5000);
    } catch (error) {
      setIsFailed("Form submission failed");
      setTimeout(() => {
        setIsFailed("");
      }, 5000);
    }
  };
  return (
    <Wrapper id="contact">
      <div className="contact-inner">
        <SubHeading>COntact Us</SubHeading>
        <Heading>
          A dedicated solution for startups and enterprises{" "}
          <img src="./images/span.png" alt="" />
        </Heading>
        <Fade bottom>
          <form method="POST" onSubmit={handleSubmit}>
            <TextField
              id="fullName"
              variant="standard"
              name="fullName"
              label="Name"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
            <TextField
              id="email"
              variant="standard"
              name="email"
              label="Email Address"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <TextField
              id="message"
              name="message"
              variant="standard"
              label="Message..."
              multiline
              value={formData.message}
              onChange={handleInputChange}
              rows={3}
              required
            />

            <input
              type="hidden"
              name="_subject"
              value="New submission from your website!"
            />
            <input type="text" name="_honey" style={{ display: "none" }} />
            <input type="hidden" name="_template" value="box" />
            <button type="submit">Send Message</button>
          </form>
        </Fade>
        {isSubmitted && (
          <div style={{ color: "green", marginTop: 20, textAlign: "center" }}>
            <Fade bottom>Submitted successfully!</Fade>{" "}
          </div>
        )}
        <div style={{ color: "red", marginTop: 20, textAlign: "center" }}>
          <Fade bottom>{isFailed}</Fade>
        </div>
      </div>
      <div className="contact-inner">
        <h5>Contact Details</h5>

        <Paragraph>
        payroll@confidepay.co.zw <br />
hr@confidepay.co.zw <br />
Telephone : +263 242 310302 <br />
Whatsapp :  +263 781 192 066
        </Paragraph>
        <Paragraph>
        44 Van Praagh avenue, Milton park, Harare
        </Paragraph>
        <ButtonWrapper>
          <a target="_blank" href="https://goo.gl/maps/kFxyxMJTgtWPK9sR8">
            View on google maps
          </a>
          <BsChevronRight />
        </ButtonWrapper>
      </div>
    </Wrapper>
  );
}

export default Contact;
