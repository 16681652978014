import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const Wrapper = styled.h4`
    text-transform:uppercase;
    color:${props => props.theme.color.lightblue};
    font-family:${props => props.theme.fam.inter};
    font-size:15px;
    letter-spacing: 2px;
`

function SubHeading({children}) {
  return (
    <Fade bottom>
    <Wrapper>{children}</Wrapper>
    </Fade>
  )
}

export default SubHeading