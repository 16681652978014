import React from 'react'
import Banner from '../components/About/Banner'
import Footer from '../components/Footer'
import Outsourced from '../components/Products/Outsourced'
import Picture from '../styled/Picture'

function OutsourcedPage() {
  return (
    <>
        <Banner pageName="Oursourced Salary Service" />
        <Outsourced />
        <Picture bgImage="pic5.webp" />
        <Footer />
    </>
  )
}

export default OutsourcedPage