import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { Link as ScrollDownLink } from "react-scroll";

const Wrapper = styled.section`
  height: 100vh;
  background: linear-gradient(to bottom, #00000041, #00000041),
    url("./images/bannerpic.webp");
  background-size: cover;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(min-width:768px){
    height:660px;
  }
  @media(min-width:1200px){
    height:100vh;
  }
  .inner{
    text-align:center;
    padding:3%;
    a{
      width:90%;
      height:50px;
      display:block;
      background:${props => props.theme.color.lightblue};
      color:#ffffff;
      display:flex;
      align-items:center;
      justify-content:center;
      margin:0 auto;
      border-radius:5px;
      max-width:440px;
    }
  }
  h1 {
    font-size: 30px;
    font-family: ${(props) => props.theme.fam.bold};
    position: relative;
    margin-bottom: 20px;
    color:#ffffff;
     margin-right: auto;
      margin-left: auto;
    width:max-content;
    @media (min-width: 768px) {
      text-align: center;
      margin-bottom: 50px;
     
      z-index: 1;
      width: max-content;
    }
    @media (min-width: 992px) {
      font-size: 60px;
    }
    img {
      position: absolute;
      width: 40%;
      bottom: -10px;
      z-index: 0;
      right: 0;
      @media (min-width: 768px) {
        right: 0%;
        max-width: 280px;
        left: unset;
        bottom: -40%;
      }
    }
  }
  p {
    font-family: ${(props) => props.theme.fam.regular};
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      margin-bottom: 20px;
      max-width: 550px;
      text-align: center;
      margin: 0 auto 20px;
    }
    @media (min-width: 992px) {
      font-size: 17px;
      line-height: 1.8;
    }
    span {
      font-weight: 900;
      color: ${(props) => props.theme.color.blue};
    }
  }


  .scroll-button{
    position:absolute;
    bottom:0;
    left:50%;
    transform:translateX(-50%);
    bottom:50px;
    &:before{
      height:50px;
      width:2px;
      background:#ffffff;
      position:absolute;
      content:"";
      bottom:-50px;
      left:50%;
    }
    .circle{
      height:70px;
      width:70px;
      border:1px solid #ffffff;
      border-radius:50%;
      display:flex;
    align-items:center;
    justify-content:center;
    background:rgba(255, 255, 255, 0.33);
    color:#ffffff;
    font-size:15px;
    font-family:${props => props.theme.fam.regular};
    }
  }
`;

function Banner() {
  return (
    <Wrapper>
      <div className="inner">
        <Fade bottom>
          <h1>
            Payroll & HR Solutions <img src="./images/span.png" alt="Span" />
          </h1>
        </Fade>
        <Fade bottom>
          <p className="p">
            We offer efficient, easy and flexible payroll management solutions
            customized to suit businesses of different sizes in Zimbabwe.
          </p>
        </Fade>
        <Link to="/contact">
          Get in touch with us for Payroll and hr solutions that work
        </Link>
      </div>
    

      <ScrollDownLink to='service' className="scroll-button">
        <div className="circle">Scroll</div>
      </ScrollDownLink>
    </Wrapper>
  );
}

export default Banner;
