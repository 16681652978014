import React from 'react'
import Footer from '../components/Footer'
import Banner from '../components/About/Banner'
import Payroll from '../components/Products/Payroll'
import Picture from '../styled/Picture'

function PayrollPage() {
  return (
    <>
         <Banner pageName="Payroll Bureau" />
        <Payroll />
        <Picture bgImage="pic1.webp" />
        <Footer />
    </>
  )
}

export default PayrollPage