import React from 'react';
import styled from 'styled-components';
import Paragraph from './Paragraph';

const Wrapper = styled.div`
width:90%;
      max-width:320px;
      margin-right:25px;
      filter: drop-shadow(0px 4px 4px rgba(174, 171, 216, 0.5));
      min-width:300px;
      box-shadow: 1px 6px 7px -2px rgba(0,0,0,0.3);
-webkit-box-shadow: 1px 6px 7px -2px rgba(0,0,0,0.3);
-moz-box-shadow: 1px 6px 7px -2px rgba(0,0,0,0.3);
border-radius:10px;
padding-bottom:20px;
@media(min-width:768px){
     margin-bottom:30px;
  }
  @media(min-width:992px){
    box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.05);
  &:hover{
    box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 6px 7px -2px rgba(0, 0, 0, 0.3);
   
  }
}

  .img-top{
    background:url('./images/${props => props.bgImage}.png');
    height:150px;
    width:100%;
    background-size:cover;
    transition:all 0.7s ease;
    @media(min-width:992px){
      max-height:0;

    }
  }
  h3{
    color:${props => props.theme.color.blue};
    transition:all 0.7s ease;
    @media(min-width:992px){
      color:${props => props.theme.color.lightblue};
      
    }
  }
  .text{
    padding:20px;
    padding-bottom:0;
    text-align:center;
    h3{
      margin-bottom:20px;
      transition:all 0.7s ease;
    }
    p{
      padding-bottom:0;
      line-height:20px;
    }
  }
  

`

function BenefitCard({bgImage, heading, text}) {
  return (
    <Wrapper bgImage={bgImage} className='benefit-card'>
      <div className="img-top">

      </div>
      <div className="text">
        <h3>{heading}</h3>
      <Paragraph>{text}</Paragraph>
      </div>
      
    </Wrapper>
  )
}

export default BenefitCard