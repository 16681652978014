import React from 'react';
import AboutUs from '../components/About/AboutUs';
import Banner from '../components/About/Banner';
import Benefits from '../components/About/Benefits';
import Team from '../components/About/Team';
import Footer from '../components/Footer';
import Ready from '../components/Home/Ready';
import Trusted from '../components/Home/Trusted';

function About() {
  return (
    <>
      <Banner pageName='About Us'/>
      <AboutUs />
     <Team />
      <Benefits />
      <Trusted />
      <Ready />
        <Footer />
    </>
  )
}

export default About