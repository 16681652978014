import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import SubHeading from "../../styled/SubHeading";
import TeamCard from "../../styled/TeamCard";

const Wrapper = styled.section`
  padding: 5%;
  @media(min-width:1200px){
    padding:5% 10%;
  }
  h4 {
    margin-bottom: 40px;
    padding-top: 20px;
  }
  h2 {
    @media (min-width: 992px) {
      margin-bottom: 70px;
      max-width:500px;
    }
    img {
      position: absolute;
      left: 0;
      width: 80%;
      max-width: 200px;
      display:none;
      bottom: -20px;
      @media (min-width: 992px) {
        max-width: 300px;
        bottom: -33px;
      }
    }
  }
  .team-inner {
    @media (min-width: 568px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    a {
      @media (min-width: 568px) {
        margin: 0 5px 20px;
        min-width: 250px;
      }
      @media (min-width: 992px) {
        width: 21%;
        min-width: 250px;
      }
    }
  }
`;

const data = [
  {
    firstName: "Shakespear",
    lastName: "Tsandukwa",
    role: "Managing Director",
    linkedIn: "https://www.linkedin.com/in/shakespear-tsandukwa-23790354",
   
  },
  {
    firstName: "Annamet",
    lastName: "Tsandukwa",
    role: "Director",
    linkedIn: "https://www.linkedin.com/in/annamet-tsandukwa-08a54785/",
    
  },
  {
    firstName: "Sarah",
    lastName: "Andirade",
    role: "Payroll Manager",
    linkedIn: "https://www.linkedin.com/in/sarah-m-andirade-0845a85a",
   
  },
  {
    firstName: "Fanwel",
    lastName: "Jawi",
    role: "Operations Manager",
    linkedIn: "https://www.linkedin.com/in/fanwell-jawi-80a82ab1/",
    
  },
];

function Team() {
  return (
    <Wrapper>
      <SubHeading>Our Team</SubHeading>
      <Heading>
        A dedicated solution for startups and enterprises{" "}
        <img src="./images/span.png" alt="" />
      </Heading>
      <div className="team-inner">
        {data.map((item, i) => {
          return (
            <TeamCard
              key={i}
              firstName={item.firstName}
              lastName={item.lastName}
              role={item.role}
              link={item.linkedIn}
            />
          );
        })}
      </div>
    </Wrapper>
  );
}

export default Team;
