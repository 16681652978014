import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";
import SubHeading from "../../styled/SubHeading";

const Wrapper = styled.section`
  padding:5%;
  @media(min-width:992px){
    display:flex;
    position:relative;
    margin-top:50px;
    padding:0;
  }
  @media(min-width:1200px){
    margin-top:150px;
  }
  img{
    width:100%;
    margin:0 auto;
    margin-bottom:20px;
    max-width:500px;
    display:block;
    @media(min-width:992px){
      position: absolute;
      left:0;
      top:-10%;
      width:40%;
    }
    @media(min-width:1200px){
    width:47%;
    max-width:580px;
    top:-18%;
  }
  }
  h4{
        margin-bottom:40px;
        padding-top:20px;

      }
      h2{
        @media(min-width:992px){
            margin-bottom:50px;
          }
        img{
          position:absolute;
          left:0;
          width:80%;
          max-width:200px;
          bottom:0px;
          @media(min-width:992px){
            max-width:300px;
            bottom:-33px;
          }
        }
      }
      .benefit-inner{
        @media (min-width:992px) {
          margin-left:25%;
         background:url('./images/rounds.png');
         background-size:cover;
         background-position:top right;
         padding:5% 10%;
         padding-left:20%;
        }
      }
      .benefit-item{
        margin-bottom:30px;
        
        h3{
          margin-bottom:20px;
          font-family:${props => props.theme.fam.bold};
          font-size:20px;
        }
      }
`;

function Benefits() {
  return (
    <Wrapper>
      <img src="./images/about-benefit.webp" alt="" />
      <div className="benefit-inner">
        <SubHeading>Benefits of working with us</SubHeading>
        <Heading>
          Getting started is <br /> as easy as 1,2,3...{" "}
          <img src="./images/span.png" alt="" />
        </Heading>
        <div className="benefit-item">
          <h3>Individual Attention</h3>
          <Paragraph>
            We will treat each client with the utmost care; we will develop and
            maintain a strong personal relationship; we will tailor make our
            products to suit every client individually; we will provide service
            with a commitment to professionalism, trust and the highest level of
            personal and professional integrity.
          </Paragraph>
        </div>
        <div className="benefit-item">
          <h3>Quick Response</h3>
          <Paragraph>
            We will respond to a client’s urgent need immediately; we will
            return all messages and e-mail communications within 24 hours; we
            will deliver and review all work products on a timely and as agreed
            basis.
          </Paragraph>
        </div>
        <div className="benefit-item">
          <h3>Proactivity</h3>
          <Paragraph>
            We are committed to understanding the goals and needs of our
            clients, responding to such needs with our best service, advice and
            products. We will strive to provide our clients with innovative
            solutions and opportunities to improve and grow their business
          </Paragraph>
        </div>
      </div>
    </Wrapper>
  );
}

export default Benefits;
