import React from 'react';
import { Fade } from 'react-reveal';
import styled from 'styled-components';
import Button from '../../styled/Button';
import Heading from '../../styled/Heading';
import Paragraph from '../../styled/Paragraph';
import SubHeading from '../../styled/SubHeading';

const Wrapper = styled.section`
padding:10% 5%;
background:url('./images/whyoutsource.png');
background-size:cover;
background-position:center bottom;
@media(min-width:1200px){
  padding:0;
  display: flex;
  align-items:center;
}
  .why-image{
    display:none;
    @media(min-width:1200px){
      display:block;
      width:40%;
      img{
        width:100%;
      }
    }
  }
  .text{
    @media(min-width:1200px){
  padding:5%;
}
    h4{
      color:#ffffff;
      @media (min-width:992px) {
          margin-bottom:50px;
         
        }
    }
    h2{
      margin-top:20px;
      color:#ffffff;
      @media (min-width:992px) {
          line-height:50px;
          font-size:40px;
        }
      br{
        display:none;
        @media (min-width:992px) {
          display:block;
        }
      }
    }
    p{
      font-size:20px;
      color:#ffffff;
      @media (min-width:992px) {
          margin-bottom:30px;
          font-size:25px;
          opacity:0.8;
        }
    }
    ul{
      list-style:unset;
      margin-top:20px;
      color:#ffffff;
      margin-left:20px;
      margin-bottom:20px;
      @media (min-width:992px) {
          margin-bottom:50px;
        }
      li{
        font-family:${props => props.theme.fam.regular};
        font-size:18px;
        margin-bottom:7px;
        @media (min-width:992px) {
          font-size:25px;
          line-height:35px;
          margin-bottom:15px;
          opacity: .8;
        }
      }
    }
    button{
      background:#ffffff;
      svg{
        color:${props => props.theme.color.skyblue};
      }
    }
  }
`

function Why() {
  return (
    <Wrapper>
      <Fade right>
      <img src="./images/why.webp" className='why-image' alt="" /></Fade>
      <div className="text">
            <SubHeading>WHy outsource?</SubHeading>
            <Heading>ConfidePay Bureau Service - An <br /> outsourced payroll management service</Heading>
            <Paragraph>A world wide practice recognised for :</Paragraph>
            <Fade bottom><ul>
              <li>Improving Efficiencies – gaining outside expertise</li>
              <li>Reducing costs</li>
              <li>Achieving legislative compliance on Payrolls – avoid penalties</li>
            </ul></Fade>
            <Button link='/payroll' text='Learn more' />
      </div>
    </Wrapper>
  )
}

export default Why