import React from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";
import SubHeading from "../../styled/SubHeading";

const Wrapper = styled.section`
  padding:5%;
  position:relative;
  h4,h2{
    text-align:center;
  }
  h4{
    padding-top:2%;
    color:#000000;
  }
  h2{
    @media(min-width:992px){
      margin-bottom:50px;
    }
    img{
      position:absolute;
      width:60%;
      max-width:150px;;
      bottom:-5%;
      left:50%;
      transform:translateX(-50%);
      @media(min-width:768px){
          bottom:-20px;
          max-width:300px;

        }
    }
  }
  .benefits-inner{
    display:flex;
    flex-direction:column-reverse;
    @media(min-width:992px){
      flex-direction:row;
      justify-content: center;
    }
    .benefits-item{
      margin-top:20px;

      @media(min-width:992px){
          width:45%;
          max-width:450px;
          
        }
        &:first-child{
          @media(min-width:768px){
          
          margin-top:auto;
          margin-right:30px;
        }
        }
      h2,h4{
        text-align: left;

      }
      h2{
        img{
          @media(min-width:992px){
            width:200px;
            left:30%;
          }
        }
      }
      h4{
        margin-bottom:20px;
        @media (min-width:768px) {
          margin-top:30px;
        }
      }
      .mission-image{
        width:100%;
        @media(min-width:768px){
          max-width:450px;
          margin:0 auto;
          display:block;
        }

      }
      p{
        margin-bottom:30px;

      }
    }
  }
  .benefit-img-small{
    display:none;
    @media(min-width:1200px){
      display: block;
      width:150px;
      position:absolute;
      right:0;
      bottom:-10%;
    }
    @media(min-width:1350px){
      
      width:200px;
    }
  }
`;

function Mission() {
  return (
    <Wrapper>
    <SubHeading>Benefits of payroll outsourcing</SubHeading>
    <Heading>
      Process your payroll better than <br /> competition <img src="./images/span.png" alt="" />
    </Heading>
    <div className="benefits-inner">
      <div className="benefits-item">
        
        <Heading>
          Understand your company’s growth in real-time
          <img src="./images/span.png" className="span" alt="" />
        </Heading>
        <SubHeading>Our Mission</SubHeading>
        <Paragraph>
        We are there to increase efficiency of our clients through innovative outsourcing services and software products.
        </Paragraph>
        <SubHeading>Vision </SubHeading>
        <Paragraph>To provide an exceptional service experience to every ConfidePay client.</Paragraph>
        <SubHeading>Core values </SubHeading>
        <Paragraph>Responsive <br />
Proactive</Paragraph>
        
      </div>
      <div className="benefits-item">
        <Fade right>
        <img className="mission-image" src="./images/mission.png" alt="" />
        </Fade>
      </div>
    </div>
    <img src="./images/benefit.png" className="benefit-img-small" alt="" />
  </Wrapper>
  )
}

export default Mission