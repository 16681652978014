import React from 'react';
import styled from 'styled-components';
import Heading from '../../styled/Heading';
import SubHeading from '../../styled/SubHeading';
import {BsChevronRight} from 'react-icons/bs';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';

const ButtonWrapper = styled.button`
  background:${props => props.theme.color.blue};
  height:45px;
  width:150px;
  border-radius:10px;
  transform:translate(20px, 20px);
  border:none;

  transition:all 0.35s ease;
  color:#ffffff;
  position:relative;
  @media (min-width: 992px) {
          height:60px;
          width:219px;
        }
        &:hover{
          transform:translate(10px, 10px);
          @media (min-width:1200px) {
            width:200px;
          }
        }
        &:hover a{
          transform:translate(0px, 0px);
        }
  a{
    background:${props => props.theme.color.lightblue};
    border-radius:10px;
    transform:translate(-31px,-12px);
    height:45px;
    display: block;
    width:150px;
    transition:all 0.35s ease;
    display: flex;
    align-items:center;
    justify-content:center;
    font-family:${props => props.theme.fam.regular};
    color:#ffffff;
    text-decoration:none;
    font-size:14px;
    @media (min-width: 992px) {
          height:60px;
          width:200px;
          font-size:16px;
        }
  }
  svg{
    color:#ffffff;
    position:absolute;
    right:0;
    top:13px;
    height:20px;
    right:8px;
    @media (min-width: 992px) {
      top: 32%;
    right: 6%;
    width: 30px;
        }
  }
`;


const Wrapper = styled.section`
padding:5%;
background:url('./images/contactus.png');
background-size:cover;
background-position:center center;
@media (min-width:992px) {
    display: flex;
    align-items:center;
    position: relative;
    margin:5% 0;
    padding:10% 5%;
    margin-bottom:0;
}
@media (min-width:1200px) {
          padding:10%;
        }
    img{
        width:100%;
        margin-top:25px;
        @media (min-width:992px) {
    width:50%;
    position: absolute;
    right:0;

}

@media (min-width:1200px) {
    width:60%;
}
    }
    .ready-inner{
        @media (min-width:992px) {
    width:450px;
}
        h4{
            margin-bottom:20px;
        }
    }
`

function Ready() {
  return (
    <Wrapper>
        <div className="ready-inner">
            <SubHeading>Contact us</SubHeading>
            <Heading>Ready to transform your company? 🚀</Heading>
            <ButtonWrapper>
      <Link to="/contact"
            >Get in touch</Link>
      <BsChevronRight />
    </ButtonWrapper>
        </div>
        <Fade bottom>
        <img src="./images/monitor.png" alt="" /></Fade>
    </Wrapper>
  )
}

export default Ready