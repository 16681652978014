import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";

const Wrapper = styled.section`
  padding: 5%;
  overflow: hidden;
  background: ${(props) => props.theme.color.lightblue};
  position: relative;
  @media (min-width: 1200px) {
    padding: 5% 10%;
  }
  img {
    width: 100%;
  }
  h2 {
    position: relative;
    width: max-content;
    color: #ffffff;
    @media (min-width: 992px) {
      margin-bottom: 50px;
    }
    span {
      z-index: 2;
      position: relative;
    }
    img {
      position: absolute;
      right: 0;
      width: 60%;
      max-width: 150px;
      z-index: 1;
      bottom: -10px;
      @media (min-width: 992px) {
        max-width: 300px;
        bottom: -18px;
      }
    }
  }
  p {
    margin-bottom: 20px;
    color: #ffffff;
  }
  ul {
    list-style: unset;
    margin-left: 13px;
    li {
      font-family: ${(props) => props.theme.fam.regular};
      font-size: 14px;
      margin-bottom: 10px;
      color: #ffffff;
      @media (min-width: 1200px) {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
  h3 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 25px;
    color: #ffffff;
    text-transform: uppercase;
    br {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    @media (min-width: 768px) {
      font-size: 40px;
      width: 50%;
    }
    @media (min-width: 992px) {
      font-size: 55px;
    }
  }
  .bluecircle {
    display: none;
    @media (min-width: 768px) {
      position: absolute;
      bottom: -20px;
      left: 0;
      display: block;
      width: 100px;
    }
    @media (min-width: 1200px) {
      width: 200px;
    }
  }
  .container {
    @media (min-width: 768px) {
      display: flex;
    }
    @media (min-width: 1200px) {
      margin-top: 100px;
    }
  }
`;

function Training() {
  return (
    <Wrapper>
      <img src="./images/whitecircle.png" className="bluecircle" alt="" />
      <Heading>
        <span>Training</span>
        <img src="./images/whitespan.png" alt="" />
      </Heading>
      <Paragraph>
        Confidepay offers superior training service that helps you gain the
        required knowledge on how to effectively use several systems that make
        your business more efficient. Our expirienced team is available to well
        equip you with information on how to use Confidepay payroll software
        products. The goal is to give you necessary tools to successfully help
        payroll personnel to get an in depth understanding of what is expected
        of them in an organizationand the relationship of payroll on other
        departments within a business system, such as human resources and
        finance.
      </Paragraph>
      <div className="container">
        <h3>
          Why <br /> choose us?
        </h3>
        <div className="inner">
          <ul>
            <li>Payroll Administration Training</li>
            <li>Payroll Software and Administration Training</li>
            <li>Salary Processing training</li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}

export default Training;
