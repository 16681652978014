import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";
import SubHeading from "../../styled/SubHeading";

const Wrapper = styled.section`
  padding: 5%;
  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 1200px) {
    padding: 5% 10%;
  }
  .about-inner {
    position: relative;
    margin-bottom: 50px;
    @media (min-width: 768px) {
      margin: 0 auto;
    }
    @media (min-width: 992px) {
      width: 45%;
      max-width: 530px;
    }
    .text {
      background: ${(props) => props.theme.color.blue};
      height: 100px;
      color: #ffffff;
      padding: 15px;
      border-radius: 15px;
      position: absolute;
      bottom: -10%;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      max-width: 300px;

      h4 {
        font-size: 38px;
      }
    }
    img {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      display: block;
      @media (min-width: 992px) {
        margin: 0 auto;
        max-width: 450px;
      }
    }
    &:last-child {
      @media (min-width: 992px) {
        display: flex;
        align-items: flex-end;
      }
    }
    &:nth-child(1) {
      @media (min-width: 992px) {
        margin-left: 0;
      }
      h4 {
        margin-bottom: 40px;
        padding-top: 20px;
      }
      h2 {
        @media (min-width: 992px) {
          margin-bottom: 50px;
        }
        img {
          position: absolute;
          left: 0;
          width: 80%;
          max-width: 200px;
          bottom: -20px;
          @media (min-width: 992px) {
            max-width: 300px;
            bottom: -33px;
          }
        }
      }
      p {
        color: #524e4e;
        margin-bottom: 20px;
      }
    }
    .bottom-number {
      display: flex;
      flex-wrap: wrap;
      .inner-number {
        width: 50%;
        h5 {
          font-family: ${(props) => props.theme.fam.inter};
          font-size: 20px;
          span {
            color: ${(props) => props.theme.color.skyblue};
          }
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
`;

function AboutUs() { 
  return (
    <Wrapper>
      <div className="about-inner">
        <SubHeading>About Us</SubHeading>
        <Heading>
          A dedicated solution for startups and enterprises{" "}
          <img src="./images/span.png" alt="" />
        </Heading>
        <Paragraph>
          ConfidePay (Pvt) Ltd is a leading payroll and HR solutions provider in
          Zimbabwe. We offer payroll administration outsource service, payments
          administration, payroll software, payroll support, training and
          payroll security mechanisms. We are dedicated to providing superior
          payroll and HR solutions customized to support organizations of any
          size in Zimbabwe.
        </Paragraph>
        <Paragraph>
          {" "}
          We have a dedicated team vastly experienced in payroll solutions
          equipped with the necessary skills and experience to give efficient,
          easy and flexible payroll management solutions that suits every
          business.{" "}
        </Paragraph>
        <Paragraph>
          The company has been in operation since the year 2005 (as ECA
          Consultancy) with traditional clients like the Astra Group, Cairns
          Foods and Telecel. We still have these clients amongst many others,
          which show our commitment to quality and detail and customer
          satisfaction. Our services and products are unequalled, highly
          customized and strictly confidential.
        </Paragraph>
        {/* <div className="bottom-number">
          <div className="inner-number">
            <h5>
              99<span>%</span>
            </h5>
            <Paragraph>Customer satisfaction</Paragraph>
          </div>
          <div className="inner-number">
            <h5>
              100<span>+</span>
            </h5>
            <Paragraph>Monthly active users</Paragraph>
          </div>
          <div className="inner-number">
            <h5>
              25<span>+</span>
            </h5>
            <Paragraph>New users per week</Paragraph>
          </div>
          <div className="inner-number">
            <h5>
              25<span>+</span>
            </h5>
            <Paragraph>New users per week</Paragraph>
          </div>
        </div> */}
      </div>
      <div className="about-inner">
        <img src="./images/about.png" alt="About Confide Pay" />
        <div className="text">
          <h4>
            10 + Years <br /> of experience
          </h4>
        </div>
      </div>
    </Wrapper>
  );
}

export default AboutUs;
