import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";

const Wrapper = styled.section`
  padding: 5%;
  overflow: hidden;
  background: ${(props) => props.theme.color.blue};
  position: relative;
  @media (min-width: 1200px) {
    padding: 5% 10%;
  }
  img {
    width: 100%;
  }
  h2 {
    position: relative;
    width: max-content;
    color: #ffffff;
    @media (min-width: 992px) {
      margin-bottom: 50px;
    }
    span {
      z-index: 2;
      position: relative;
    }
    img {
      position: absolute;
      right: 0;
      width: 60%;
      max-width: 150px;
      z-index: 1;
      bottom: -10px;
      @media (min-width: 992px) {
        max-width: 300px;
        bottom: -18px;
      }
    }
  }
  p {
    margin-bottom: 30px;
    color: #ffffff;
  }
  ul {
    list-style: unset;
    margin-left: 13px;
    li {
      font-family: ${(props) => props.theme.fam.regular};
      font-size: 14px;
      margin-bottom: 10px;
      color: #ffffff;
      @media (min-width: 1200px) {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
  h3 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 25px;
    color: #ffffff;
    text-transform: uppercase;
    br {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    @media (min-width: 768px) {
      font-size: 40px;
      width: 50%;
    }
    @media (min-width: 992px) {
      font-size: 55px;
    }
  }
  .bluecircle {
    display: none;
    @media (min-width: 768px) {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100px;
    }
    @media (min-width: 1200px) {
      width: 200px;
    }
  }
  .container {
    @media (min-width: 768px) {
      display: flex;
    }
    @media (min-width: 1200px) {
      margin-top: 100px;
    }
  }
`;

function HumanResources() {
  return (
    <Wrapper>
      <img src="./images/whitecircle.png" className="bluecircle" alt="" />
      <Heading>
        <span>Human Resources</span>
        <img src="./images/span.png" alt="" />
      </Heading>
      <Paragraph>
        We provide automated human resources through cutting edge software
        customized to support organizations of any size in adding value to their
        businesses. Confidepay enables all your employees’ information secured
        in one place and having all human resources functions automated.
        Additionally our innovative solutions minimize legal risk and have a
        quick response time helping your business become more efficient.
      </Paragraph>
      <div className="container">
        <h3>
          Why <br /> choose us?
        </h3>
        <div className="inner">
          <ul>
            <li>Recruit, hiring and onboarding new employees</li>
            <li>Handling employee compensation and benefits information</li>
            <li>Employee job career development</li>
            <li>Development of organisational policies</li>
            <li>Identify staff training needs and facilitate trainings</li>
            <li>Resource Management</li>
            <li>Remuneration restructuring and advisory services</li>
            <li>General employment law advice</li>
            <li>Advice on production of employment contracts</li>
            <li>Pensions advice and implementation of pension scheme</li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}

export default HumanResources;
