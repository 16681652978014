import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Header from "../Header";

const Outer = styled.div`
    header{
        .hamburger.small,.hamburger.big{
            outline-color:#ffffff;
            span{
                background:#ffffff;
                &:before,&:after{
                    background:#ffffff;
                }
            }
        }
        .open.small.hamburger,.open.hamburger.big{
            outline-color:${props => props.theme.color.lightblue};
            span{
                background:transparent;
                &:before,&:after{
                    background:${props => props.theme.color.lightblue};
                }
            }
        }
        .menu{
             .hamburger.small,.hamburger.big{
            outline-color:#ffffff;
            span{
                background:#ffffff;
                &:before,&:after{
                    background:#ffffff;
                }
            }
        }
        }
       @media (min-width:768px) {
        z-index:5;
        ul{
            li{
                a{
                    color:#ffffff;
                    &:hover{
                        opacity:0.5;
                        color:#ffffff;
                    }
                }
                .active{
                    color:#ffffff;
                    &:before{
                        background:#ffffff;
                    }
                }
            }
        }
       }
        
    }
    
`

const Wrapper = styled.section`
    background:linear-gradient(to bottom, #00000056,#00000056), url('./images/${props => props.imageUrl || 'banner2.jpg'}');
    background-size:cover;
    height:230px;
    display: flex;
    padding:5%;
    align-items: flex-end;
    position:relative;
    overflow:hidden;
    @media(min-width:1200px){
        padding:5% 10%;
    }
    .link{
        display: flex;
        text-transform:uppercase;
        color:#ffffff;
        font-weight:600;
        font-size:15px;
        margin-bottom:50px;
        a{
            margin-right:10px;
            color:${props => props.theme.color.lightblue};
        }
        p{
            margin-left:10px;
        }
    }
    
    img{
        
        position: absolute;
        bottom: -35%;
        right: 0;
        width: 100px;
    }
`;

function Banner({ pageName, imageUrl }) {
  return (
    <Outer className="outtter">
      <Header />
      <Wrapper imageUrl={imageUrl}>
        <div className="link">
          <Link to="/">Confide Pay</Link>
          •
          <p>{pageName}</p>
        </div>
        <img src="./images/round.png" alt="" />
      </Wrapper>
      
    </Outer>
  );
}

export default Banner;
