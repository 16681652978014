/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { BsFilePdf } from 'react-icons/bs';
import styled from 'styled-components';

const Wrapper = styled.section`
    padding:5%;
    @media (min-width:768px) {
        display: flex;
        flex-wrap:wrap;
        justify-content: space-between;
    }
    @media (min-width:1200px) {
        padding:5% 10%;
        max-width:1300px;
        margin:0 auto;
    }
    .inner{
        margin-bottom:30px;
        @media (min-width:1200px) {
            
        }
        h5{
            font-size:18px;
            margin-bottom:20px;
            font-family:${props => props.theme.fam.bold};
            line-height:30px;
        }
        a{
            display: block;
            text-decoration:none;
            color:#000000;
            padding:7px 0;
        }
    }
`

function Resources() {
  return (
    <Wrapper>
        <div className="inner">
            <h5>Company Documents: Tax Clearance:</h5>
            <a download={true} href={"./images/span.png"}>
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
        </div>
        <div className="inner">
            <h5>Statutory Documents: <br />
NSSA Insurable Earnings Update</h5>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
        </div>
        <div className="inner">
            <h5>PRAZ Registration Certificate:</h5>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
            <a href="#">
                <BsFilePdf /> Confide Pay Tax Clearance 2023
            </a>
        </div>
      
    </Wrapper>
  )
}

export default Resources