import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";

const Wrapper = styled.section`
    padding:5%;
   overflow:hidden;
   position:relative;
    img{
        width:100%;
    }
    h2 {
    position: relative;
    width:max-content;
    @media (min-width: 992px) {
      margin-bottom: 50px;
    }
    span {
      z-index: 2;
      position: relative;
    }
    img {
      position: absolute;
      right: 0;
      width: 60%;
      max-width: 150px;
      z-index: 1;
      bottom: -10px;
      @media (min-width: 992px) {
        max-width: 300px;
        bottom: -33px;
      }
    }
  }
  p{
    margin-bottom:30px;
  }
  ul{
    list-style: unset;
    margin-left:13px;
    li{
      font-family:${props => props.theme.fam.regular};
      font-size:14px;
      margin-bottom:10px;
      @media (min-width:1200px) {
        font-size:16px;
        margin-bottom:15px;
      }
    }
  }
  .container{
    @media(min-width:768px){
        display:flex;
        justify-content:space-between;
        .inner{
            width:45%;
        }
    }
  }
  h4{
    margin-bottom:20px;
    margin-top:20px;
  }
  .teamCardtop{
    display:none;
    @media(min-width:992px){
        display:block;
        position:absolute;
        bottom:0;
        right:10%;
        width:200px;
    }
  }
 .bluecircle{
    display:none;
    @media (min-width: 768px) {
      position: absolute;
      bottom: -100px;
      left: 0;
      display: block;
      width: 100px;
    }
    @media (min-width: 1200px) {
      width: 200px;
    }
  }
`;

function Payroll() {
  return (
    <Wrapper>
      <img src="./images/bluecircle.png" className="bluecircle" alt="" />
      <img src="./images/teamcardtop.png" className="teamCardtop" alt="" />
      <div className="container">
        <div className="inner">
          <Heading>
            <span>Payroll Bureau</span>
            <img src="./images/span.png" alt="" />
          </Heading>
          <Paragraph>
          Confide Payroll System is a superior service designed for an efficient, easy and flexible payroll routine. It is user friendly and can be customised by the user to suit any situation. One does not have to be a payroll expert to use Confide. Our flexible and customized software strives to provide our clients with innovative payroll solutions and opportunities to improve and grow their business.
          </Paragraph>
        </div>
        <div className="inner">
          <ul>
            <li>Our software can be outsourced</li>
            <li>Can be customized to suit any your situation</li>
            <li>Easy to use</li>
          </ul>
          <h4>Capable of handling:</h4>
          <ul>
            <li>Company car benefits</li>
            <li>Commissions</li>
            <li>PAYE</li>
            <li>Funeral Policies</li>
            <li>Medical Aid</li>
            <li>NSSA</li>
            <li>NEC</li>
            <li>Loans</li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}

export default Payroll;
