import React from 'react';
import Banner from '../components/About/Banner';
import BlogSection from '../components/Blog/BlogSection';
import Footer from '../components/Footer';



function BlogPage({blogData}) {
  return (
    <>
    
        <Banner pageName="Blogs" />
        <BlogSection articles={blogData} />
        <Footer />
    </>
  )
}

export default BlogPage