import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
    height: 300px;
  background: linear-gradient(to bottom, #00000041, #00000041),
    url("./images/${props => props.bgImage}");
  background-size: cover;
  @media(min-width:768px){
    height:600px;
  }
`

function Picture({bgImage}) {
  return (
    <Wrapper bgImage={bgImage}>

    </Wrapper>
  )
}

export default Picture