import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";

const Wrapper = styled.section`
   padding: 5%;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  @media (min-width: 1200px) {
    padding: 5% 10%;
  }
  img {
    width: 100%;
  }
  h2 {
    position: relative;
    width: max-content;
    color: #000000;
    @media (min-width: 992px) {
      margin-bottom: 50px;
    }
    span {
      z-index: 2;
      position: relative;
    }
    img {
      position: absolute;
      right: 0;
      width: 60%;
      max-width: 150px;
      z-index: 1;
      bottom: -10px;
      @media (min-width: 992px) {
        max-width: 300px;
        bottom: -18px;
      }
    }
  }
  p {
    margin-bottom: 20px;
    color: #000000;
  }
  ul {
    list-style: unset;
    margin-left: 13px;
    li {
      font-family: ${(props) => props.theme.fam.regular};
      font-size: 14px;
      margin-bottom: 10px;
      color: #000000;
      @media (min-width: 1200px) {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
  h3 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 25px;
    color: #000000;
    width:max-content;
    text-transform: uppercase;
    br {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    @media (min-width: 768px) {
      font-size: 40px;
      
    }
    @media (min-width: 992px) {
      font-size: 55px;
    }
  }
  .bluecircle {
    display: none;
    @media (min-width: 768px) {
      position: absolute;
      bottom: -100px;
      left: 0;
      display: block;
      width: 100px;
    }
    @media (min-width: 1200px) {
      width: 200px;
    }
  }
  .container {
    @media (min-width: 768px) {
      display: flex;
      justify-content:space-between;
      .inner{
        width:46%;
        
      }
    }
    @media (min-width: 1200px) {
      margin-top: 50px;
        .inner{
            margin-bottom:50px;
        }
    }
  }
`;

function Outsourced() {
  return (
    <Wrapper>
      <img src="./images/bluecircle.png" className="bluecircle" alt="" />
      <div className="container">
        <div className="inner">
          <Heading>
            <span>Outsourced <br />
salary service</span>
            <img src="./images/span.png" alt="" />
          </Heading>
          <Paragraph>
          Condifidepay in line with Zimbabwe statutory instruments allows organisations to outsource their payroll function to experts who are well versed and dedicated to serve the needs of employers. Outsourcing enables companies to add value to their organization by focusing on more productive areas leaving the non core areas to the professionals.
          </Paragraph>
        </div>
        <div className="inner">
          <h3>Why <br /> choose us?</h3>
          <ul>
            <li>Businesses save time</li>
            <li>Reduce compliance risk</li>
            <li>Ensure employees are paid accurately</li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}

export default Outsourced;
