import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import Header from "../Header";
import Paragraph from "../../styled/Paragraph";
import {CiClock2} from 'react-icons/ci';
import dot from '../../images/mydot1.png'
import Footer from "../Footer";

const Wrapper = styled.section`
    .top-banner{
        height:300px;
        background:linear-gradient(to bottom, #00000035,#00000035), url('${props => props.bgImage}');
    background-size: cover;
    background-position: center center;;
    padding:5%;
    padding-bottom:10%;
    display:flex;
    align-items:flex-end;
    justify-content:flex-start;
    @media(min-width:1200px){
            padding:5% 10%;
        }
        .inner{
          @media(min-width:1200px){
            width:60%;
        }
        }
        h2{
            font-size:40px;
            text-transform:uppercase;
            @media(min-width:1200px){
            font-size:50px;
        }
        }
    h2,p{
        color:#ffffff;
    }
    p{
        display:flex;
        flex-direction: column;
        
        @media(min-width:568px){
          flex-direction:row;
        }
        img{
            width:25px;
            margin:0 10px;
            object-fit:contain;
            display: none;
            @media(min-width:568px){
          display: block;
        }
        }
    }
    }
    .inner-details{
        padding:5%;
        @media(min-width:1200px){
            padding:5% 10%;
        }
        h2,h1,h3,h4,h5,h6,p{
          margin-bottom:20px;
        }
        p{
          color:#121416;
        }
        img,video{
          width:100%;
          margin-bottom:20px;
        }

        h4{
            font-size:20px;
    font-family:${props => props.theme.fam.bold};
    position:relative;
    margin-bottom:20px;
    @media(min-width:768px){
      font-size:25px;
    }
    
        }
        .detail{
            margin-bottom:30px;
        }
        p{
          font-family:${props => props.theme.fam.regular};
    font-size:14px;
    color:#000000;
    line-height:20px;
    @media (min-width:1200px) {
      font-size:16px;
    }
        }
    }
`

function BlogPostDetails({ blogData }) {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    let post = blogData.find((post) => post.slug === slug);

    if (post) {
      setBlog(post);
    }
  }, [blog, blogData, slug]);

  if (!blog) {
    return <div>Post not found</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <Header />
      <Wrapper className="blog-post-details" bgImage={blog.featured_image}>
        <div className="top-banner">
            <div className="inner">
            <h2>{blog.title}</h2>
            <Paragraph>By {blog.author.name} <img src={dot} alt="" /><span><CiClock2 /> 2 minute read</span>  <img src={dot} alt="" />{formatDate(blog.date)}</Paragraph>
            </div>
        </div>
       <div className="inner-details">
       <div dangerouslySetInnerHTML={{ __html: blog.content }} />
     
         {/* {blog.sections.map((section) => (
          <div className="detail" key={section.heading}>
            <h4>{section.heading}</h4>
            <Paragraph>{section.paragraph}</Paragraph>
          </div>
        ))}*/}
        </div> 
        
      </Wrapper>
      <Footer />
    </>
  );
}

export default BlogPostDetails;
