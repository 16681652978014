import React from 'react'
import Footer from '../components/Footer'
import Banner from '../components/About/Banner'
import PayrollBureau from '../components/Products/PayrollBureau'
import Picture from '../styled/Picture'

function PBPage() {
  return (
    <>
         <Banner pageName="Payroll Bureau" />
        <PayrollBureau />
        <Picture bgImage="pic3.webp" />
        <Footer />
    </>
  )
}

export default PBPage