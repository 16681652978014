import React from 'react'
import Faqs from '../components/Faqs/Faqs'
import Banner from '../components/About/Banner'
import Ready from '../components/Home/Ready'
import Footer from '../components/Footer'

function FaqPage() {
  return (
    <>
        <Banner imageUrl="banner2.jpg" pageName="Frequently Asked Questions" />
        <Faqs />
        <Ready />
        <Footer />
    </>
  )
}

export default FaqPage