import React from 'react'
import Banner from '../components/About/Banner'
import Footer from '../components/Footer'
import Ready from '../components/Home/Ready'
import Resources from '../components/Resources/Resources'

function ResourcesPage() {
  return (
    <>
        <Banner pageName="Resources"/>
        <Resources />
        <Ready />
        <Footer />
    </>
  )
}

export default ResourcesPage